import React from "react";
import { motion } from "framer-motion";

const Main = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-700 text-white">
      <motion.div 
        initial={{ opacity: 0, scale: 0.8 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1 }}
        className="p-8 bg-white text-gray-800 rounded-2xl shadow-2xl text-center max-w-md">
        <motion.h1 
          className="text-4xl font-bold"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}>
          🚧 Site Under Maintenance 🚧
        </motion.h1>
        <motion.p 
          className="mt-4 text-lg"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}>
          Currently updating the website to a better version. Will be back soon!  
        </motion.p>
        <motion.p 
          className="mt-2 text-lg"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}>
          현재 사이트를 더 멋진 모습으로 업데이트하고 있습니다. 곧 돌아올게요! 
        </motion.p>
        <motion.div 
          className="mt-6 text-5xl"
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 3, ease: "linear" }}>
          🔧
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Main;
